<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path:'/illnessManage' }">疾病管理</el-breadcrumb-item>
            <el-breadcrumb-item>{{name}}</el-breadcrumb-item>
            <el-breadcrumb-item>添加患者</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="add">
            <!-- 搜索患者： -->
            <el-input placeholder="请输入患者姓名或电话号或id" clearable prefix-icon="el-icon-search" v-model="search"
                style="width: 280px;" size="small">
            </el-input>
            <el-button type="primary" style="margin-left:20px" @click="query" size="small">查询</el-button>
        </div>
        <el-table :data="list" border style="width: 100%">
            <el-table-column prop="Cust_ID" label="用户id"></el-table-column>
            <el-table-column prop="Cust_Name" label="姓名"></el-table-column>
            <el-table-column label="年龄" prop="Cust_Age"></el-table-column>
            <el-table-column label="性别" prop="Cust_Sex">
                <template slot-scope="scope">
                    {{ scope.row.Cust_Sex == 0?'男':'女'}}
                </template>
            </el-table-column>
            <el-table-column label="疾病" prop="ill_name"></el-table-column>
            <el-table-column>
                <template slot-scope="scope">
                    <div class="opt">
                        <el-button type="warning" size="mini" @click="del(scope.row,scope.$index)">解除绑定</el-button>
                        <!-- <a @click="del(scope.row,scope.$index)">解除绑定</a> -->
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p>
        <el-dialog title="患者详情" :visible.sync="dialogFormVisible">
            <el-form v-model="form" class="tcform" label-width="170px" style="text-align: left;">
                <el-form-item label="用户id：">
                    <span>{{form.Cust_ID}}</span>
                </el-form-item>
                <el-form-item label="姓名：">
                    <span>{{form.Cust_Name}}</span>
                </el-form-item>
                <el-form-item label="年龄：">
                    <span>{{form.Cust_Age}}</span>
                </el-form-item>
                <el-form-item label="性别：">
                    <span>{{form.Cust_Sex == 0?'男':'女'}}</span>
                </el-form-item>
                <el-form-item label="疾病：">
                    <span>{{form.MWH_illness}}</span>
                </el-form-item>
                <el-form-item label="已绑定疾病：">
                    <span>{{bindillname}}</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false;form = {};bindillname=''">取 消</el-button>
                <el-button type="primary" @click="sub(form)">绑 定</el-button>
                <el-button type="warning" @click="del(form)">解 绑</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        inject: ['reload'],
        data() {
            return {
                list: [],
                current: 1,//默认显示第几页
                totalCount: 0,// 总条数，根据接口获取数据长度
                pageSizes: [20],// 个数选择器（可修改）
                PageSize: 20,// 默认每页显示的条数（可修改）
                value: false,
                name: '',
                id: '',
                options: [],
                select: '',
                search: '',
                dialogFormVisible: false,
                cust_id: '',
                form: {
                    Cust_ID: '',
                    Cust_Name: '',
                    Cust_Age: '',
                    Cust_Sex: '',
                    MWH_illness: ''
                },
                bindillname: '',
            }
        },
        mounted() {
            this.name = this.$route.query.name;
            this.id = this.$route.query.ill_id;
            this.getcustlist(1);
        },
        methods: {
            getcustlist(page) {
                this.axios.get("/gu/get_gu_ill_lib_cust_by_ill?page=" + page + "&size=" + this.PageSize + "&ill_lib_id=" + this.id)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.totalCount = res.data.count;
                            this.list = res.data.result;
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            async query() {
                const projectObj = await this.cha();
                const projectObj1 = await this.custdetail(projectObj.Cust_ID)
                this.bindillname = projectObj1;
            },
            cha() {
                const projectObj = this.axios.post("/gu/search_gu_custs", this.qs.stringify({ keyword: this.search }))
                    .then((res) => {
                        console.log(res)
                        if (res.data.code == 0) {
                            let arr = res.data.result[0];
                            this.form.Cust_ID = arr.Cust_ID;
                            this.form.Cust_Name = arr.Cust_Name;
                            this.form.Cust_Age = arr.Cust_Age;
                            this.form.Cust_Sex = arr.Cust_Sex;
                            this.form.MWH_illness = arr.MWH_illness;
                            this.dialogFormVisible = true;
                            return res.data.result[0];
                        } else {
                            this.$message({
                                type: 'error',
                                message: '没有查到该患者'
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                return projectObj;
            },
            custdetail(Cust_ID) {
                let str = '';
                const projectObj = this.axios.get("/gu/get_gu_ill_lib_cust_by_cust?cust_id=" + Cust_ID)
                    .then((res) => {
                        if (res.data.code == 0) {
                            let array = res.data.result;
                            if (res.data.result.length > 1) {
                                for (let i = 0; i < array.length; i++) {
                                    const element = array[i];
                                    str += element.name + '，'
                                }
                                str = str.substring(0, str.length - 1);
                            } else {
                                str = res.data.result[0].name;
                            }
                            return str;
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                return projectObj;
            },
            sub(form) {
                this.axios.post("/gu/insert_gu_ill_lib_cust", this.qs.stringify({ cust_id: form.Cust_ID, ill_lib_id: this.id }))
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$message({
                                message: '绑定成功',
                                type: 'success'
                            });
                            this.reload();
                        } else {
                            this.$message(res.data.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            del(row) {
                this.$confirm('此操作将解除绑定关系, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/gu/delete_gu_ill_lib_cust", this.qs.stringify({ cust_id: row.Cust_ID, ill_lib_id: this.id }))
                        .then((res) => {
                            if (res.data.code == 0) {
                                this.$message({
                                    message: '解除绑定成功',
                                    type: 'success'
                                });
                                this.reload();
                            } else {
                                this.$message(res.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消解除绑定'
                    });
                });
            },
            handleSizeChange() { },
            handleCurrentChange(currentPage) {
                this.getcustlist(currentPage);
            },
        },
    }
</script>
<style>
    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
    }

    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }

    .el-select {
        float: left;
    }

    .add .el-button {
        margin-right: 20px;
    }
</style>